import * as React from 'react'
import { Helmet } from 'react-helmet'

import { useSiteConfig } from '@thg-commerce/enterprise-core'

import { ProvenanceBrandHeaderComponent } from './styles'

export interface ProvenanceBrandHeaderProps {
  brand?: string
  brandId?: string
}

export const ProvenanceBrandHeader = ({
  brand,
  brandId,
}: ProvenanceBrandHeaderProps) => {
  const {
    provenanceSdkUrl,
    provenanceSdkIntegrityHash,
    provenanceBrandBundleUrl,
  } = useSiteConfig()

  if (
    !provenanceSdkUrl ||
    !provenanceBrandBundleUrl ||
    !provenanceSdkIntegrityHash
  ) {
    return null
  }

  const provenanceBundle = React.createElement('provenance-bundle', {
    url: provenanceBrandBundleUrl,
    schema: 'Brand',
  })

  return (
    <ProvenanceBrandHeaderComponent>
      <Helmet>
        <script
          type="text/javascript"
          crossOrigin="anonymous"
          src={provenanceSdkUrl}
          integrity={provenanceSdkIntegrityHash}
          async
        />
      </Helmet>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@type': 'Brand',
            '@context': 'https://schema.org',
            name: brand,
            identifier: brandId,
          }),
        }}
      />
      {provenanceBundle}
    </ProvenanceBrandHeaderComponent>
  )
}
