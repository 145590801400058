import * as React from 'react'

import { HorizontalAlignment, useTheme } from '@thg-commerce/enterprise-core'
import { spacing } from '@thg-commerce/enterprise-theme'
import { SocialEmbed } from '@thg-commerce/enterprise-widget-social-embed'
import { Alignment } from '@thg-commerce/gravity-patterns/Header/types'
import { Picture, PictureProps } from '@thg-commerce/gravity-system'
import { withPrefetch } from '@thg-commerce/gravity-system/prefetch'

import {
  ProvenanceBrandHeader,
  ProvenanceBrandHeaderProps,
} from './ProvenanceBrandHeader'
import {
  Container,
  Content,
  ContentContainer,
  CTAWrapper,
  GlobalVideoTextImaCtaPicture,
  GlobalVideoTextImaCtaPictureLink,
  ImageAnnotationText,
  ImageContainer,
  StyledButton,
  TextContainer,
  Title,
} from './styles'
import { ItemAlignment, SurfaceBorder, SurfaceStyle } from './types'

const PrefetchStyledButton = withPrefetch('href', StyledButton)
export interface ButtonProps {
  text: string
  url?: string
}

export interface GlobalVideoTextImageCtaProps {
  itemImageLink?: string
  itemImage?: string
  alt?: string
}

export interface ImageTextBlockProps {
  picture: PictureProps
  widgetIndex: number
  surface: {
    style: SurfaceStyle | string
    alignment: ItemAlignment | string
    border?: SurfaceBorder
  }
  textAlign?: HorizontalAlignment
  title?: {
    text: string
    horizontalAlignment?: HorizontalAlignment
  }
  content?: {
    text: string
    horizontalAlignment?: HorizontalAlignment
  }
  CTAs?: ButtonProps[]
  image?: {
    urls: {
      small: string
      large: string
    }
    alt?: string
    link?: string
    aspectRatio?: string
  }
  video?: {
    videoUrl: string
    transcript?: string
  }
  annotation?: {
    text: string
    productText: string
    productTextUrl: string
  }
  URL?: string
  ctaHorizontalAlignment?: HorizontalAlignment | string
  className?: string
  onClick?: (e: React.MouseEvent) => void
  horizontalAlignment?: Alignment
  globalVideoTextImageCta?: GlobalVideoTextImageCtaProps
  provenanceBrandHeader?: ProvenanceBrandHeaderProps
}

const getStyledPicture = (props: ImageTextBlockProps) =>
  props.image && (
    <Picture
      {...props.picture}
      lazy={props.widgetIndex > 1}
      aspectRatio={props?.image?.aspectRatio || '2 / 1'}
      width="100%"
    />
  )

export const ImageTextBlock = (props: ImageTextBlockProps) => {
  const theme = useTheme()
  const styledPicture = getStyledPicture(props)

  const styledGlobalVideoTextImaCtaPicture = props.globalVideoTextImageCta
    ?.itemImage && (
    <GlobalVideoTextImaCtaPicture
      {...props.picture}
      lazy={props.widgetIndex > 1}
    />
  )

  const globalVideoTextImaCtaPicture = props.globalVideoTextImageCta
    ?.itemImageLink ? (
    <GlobalVideoTextImaCtaPictureLink
      href={props.globalVideoTextImageCta.itemImageLink}
      data-testid="extra-image-link"
    >
      {styledGlobalVideoTextImaCtaPicture}
    </GlobalVideoTextImaCtaPictureLink>
  ) : (
    styledGlobalVideoTextImaCtaPicture
  )

  const picture = props.URL ? (
    <a href={props.URL}>{styledPicture}</a>
  ) : (
    styledPicture
  )

  const CTAs = props.CTAs?.map((item, index) => {
    const itemUrl = item.url || props.URL
    return (
      item.text &&
      itemUrl && (
        <PrefetchStyledButton
          key={index}
          href={itemUrl}
          renderedAs="a"
          emphasis="medium"
        >
          {item.text}
        </PrefetchStyledButton>
      )
    )
  })

  const alignment = props.surface.alignment

  const imageTextBlock = (
    <Container
      surfaceStyle={props.surface.style}
      className={props.className}
      data-testid="image-text-block-container"
    >
      <ContentContainer
        surfaceStyle={props.surface.style}
        horizontalAlignment={props.horizontalAlignment}
        border={props.surface.border}
        data-testid="image-text-block-grid"
        directions={['column', 'column', 'row']}
      >
        {props.image && (
          <ImageContainer
            flexBasis={['100%', 'calc(50%)']}
            orders={[1, 1, alignment === 'right' ? 2 : 1]}
          >
            {props.image?.urls.small || props.image?.urls.large
              ? picture
              : props.video && (
                  <SocialEmbed
                    provider="youtube"
                    url={props.video.videoUrl}
                    isAmp={false}
                  />
                )}
            {props.annotation?.text && (
              <ImageAnnotationText>
                <p>{props.annotation.text}</p>
                {props.annotation?.productTextUrl ? (
                  <a href={props.annotation?.productTextUrl}>
                    {props.annotation.productText}
                  </a>
                ) : (
                  <ImageAnnotationText style={{ marginLeft: spacing(1) }}>
                    <p>{props.annotation.productText}</p>
                  </ImageAnnotationText>
                )}
              </ImageAnnotationText>
            )}
          </ImageContainer>
        )}
        <TextContainer
          surfaceStyle={props.surface.style}
          textAlign={props.textAlign}
          verticalAlignment={props.horizontalAlignment}
          flexBasis={['100%', props.image ? 'calc(50%)' : '100%']}
          orders={[2, 2, alignment === 'right' ? 1 : 2]}
        >
          {props.title?.text && (
            <Title horizontalAlignment={props.title.horizontalAlignment}>
              {props.title.text}
            </Title>
          )}
          {props.content && (
            <Content
              content={props.content.text}
              horizontalAlignment={props.content.horizontalAlignment}
            />
          )}
          {globalVideoTextImaCtaPicture}
          <CTAWrapper ctaHorizontalAlignment={props.ctaHorizontalAlignment}>
            {CTAs}
          </CTAWrapper>
          {props.provenanceBrandHeader?.brandId &&
            props.provenanceBrandHeader.brand && (
              <ProvenanceBrandHeader {...props.provenanceBrandHeader} />
            )}
        </TextContainer>
      </ContentContainer>
    </Container>
  )

  return theme.widget.imageTextBlock.enableContainerCTA && props.URL ? (
    <a href={props.URL} style={{ textDecoration: 'none' }}>
      {imageTextBlock}
    </a>
  ) : (
    imageTextBlock
  )
}
